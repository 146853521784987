import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  { path: "", loadChildren: "../app/login/login.module#LoginModule" },
  {
    path: "group_list",
    loadChildren: "../app/group-list/group-list.module#GroupListModule",
  },
  {
    path: "employee_list",
    loadChildren:
      "../app/employee-list/employee-list.module#EmployeeListModule",
  },
  {
    path: "geo_list",
    loadChildren: "../app/geo-list/geo-list.module#GeoListModule",
  },
  {
    path: "users_list",
    loadChildren: "../app/users-list/users-list.module#UsersListModule",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
