import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpService } from "./http.service";
import { HttpModule } from "@angular/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PusherService } from "./pusher.service";
import { AgmCoreModule } from "@agm/core";
import { GeofenceComponent } from "./utils/geofence/geofence.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WebStorageModule } from "ngx-store";
import { WebcamModule } from "ngx-webcam";
import { NgxLoadingModule } from "ngx-loading";
import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "./matrial.module";
import { SharedContainerModule } from "./shared_container.module";

@NgModule({
  declarations: [AppComponent, GeofenceComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    ToastrModule.forRoot(),
    WebcamModule,
    SharedContainerModule,
    WebStorageModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    HttpClientModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      // please get your own API key here: https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: "AIzaSyBVnW1Z3yVC1_eYaoOgl0rqkARuahuJV04",
      libraries: ["geometry"],
    }),
  ],
  providers: [HttpService, PusherService],
  bootstrap: [AppComponent],
})
export class AppModule {}
