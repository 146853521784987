import { NgModule } from "@angular/core";

import {
  MatTableModule,
  MatDialogModule,
  MatFormFieldModule,
  MatButtonModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule,
  MatPaginatorModule,
} from "@angular/material";
import { FormsModule } from "@angular/forms";
@NgModule({
  imports: [
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    MatIconModule,
    MatPaginatorModule,
  ],
  exports: [
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatPaginatorModule,
  ],
})
export class MaterialModule {}
