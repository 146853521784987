import { NgModule } from "@angular/core";

import { MenuComponent } from "./menu/menu.component";
import { MaterialModule } from "./matrial.module";
import { GeoFenceService } from "./utils/services/GeoFenceService";
import { GroupService } from "./utils/services/GroupService";

@NgModule({
  imports: [MaterialModule],
  exports: [MenuComponent],
  providers: [GeoFenceService, GroupService],
  declarations: [MenuComponent],
})
export class SharedContainerModule {}
