import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import "rxjs/add/operator/map";
import { Http, Response, Headers, RequestOptions } from "@angular/http";

const url = environment.urlLink;

@Injectable()
export class GeoFenceService {
  private headers: Headers;
  private options: RequestOptions;
  constructor(private http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.options = new RequestOptions({ headers: this.headers });
  }

  getLocations() {
    return this.http
      .get(url + "/geofence/gLocations")
      .map((response) => response.json());
  }

  getGeoFence() {
    return this.http
      .get(url + "/geofence/gGeoFence")
      .map((response) => response.json());
  }

  cGeoFence(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/geofence/cGeoFence", body, this.options)
      .map((res: Response) => res.json());
  }

  uGeoFence(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/geofence/uGeoFence", body, this.options)
      .map((res: Response) => res.json());
  }

  dGeoFence(data) {
    console.log(data);
    const body = data;
    return this.http
      .post(url + "/geofence/dGeoFence", body, this.options)
      .map((res: Response) => res.json());
  }
}
