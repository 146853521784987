import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import "rxjs/add/operator/map";
import { Http, Response, Headers, RequestOptions } from "@angular/http";

const url = environment.urlLink;

@Injectable()
export class GroupService {
  private headers: Headers;
  private options: RequestOptions;
  constructor(private http: Http) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    this.options = new RequestOptions({ headers: this.headers });
  }

  getGroup() {
    return this.http
      .get(url + "/group/gGroups")
      .map((response) => response.json());
  }

  cGroup(data) {
    const body = data;
    return this.http
      .post(url + "/group/cGroup", body, this.options)
      .map((res: Response) => res.json());
  }

  uGroup(data) {
    const body = data;
    return this.http
      .post(url + "/group/uGroup", body, this.options)
      .map((res: Response) => res.json());
  }

  dGroup(data) {
    const body = data;
    return this.http
      .post(url + "/group/dGroup", body, this.options)
      .map((res: Response) => res.json());
  }
}
